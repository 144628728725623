<template>
  <div>
    <module-box :appModule="appModule">
      <div class="todo-panel-container">
        <div class="todo-item"
             v-for="(item, index) in todoList"
             :class="item.type"
             :key="`${index}`"
             @click="linkTo(item)">

          <div class="title">{{item.title}}</div>
          <div class="number">{{item.count || 0}}</div>
        </div>
      </div>
    </module-box>
    <audio ref='audioPlay'  hidden='true' >
      <source src='./media/youhavemessage.wav' type="audio/mpeg" />
    </audio>
  </div>

</template>

<script>
import ModuleBox from './ModuleBox'
import { queryTodoCountURL, getNewWorkOrderPermission,get3WorkOrderPermission } from '../api'
import moment from 'moment'
export default {
  name: 'TodoPanel',
  props: {
    appModule: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      todoList: [],
      modelIds: [],
      orderMap: {
        'newTicket': 'newTicket',
        'receiveTicketsOvertime': 'receiveTicketsOvertime',
        'processingTicketOvertime': 'processingTicketOvertime'
      },
      timer: undefined,
      hasNewWorkOrderPermission: false
    }
  },
  components: {
    ModuleBox
  },
  methods: {
    async linkTo (item) {
      console.log(item)
      let referEndTime = moment().format('YYYY-MM-DD')
      let referStartTime = moment().subtract(90, 'd').format('YYYY-MM-DD')
      let createStartTime = moment().subtract(90, 'd').format('YYYY-MM-DD HH:mm:ss')
      let createEndTime = moment().format('YYYY-MM-DD HH:mm:ss')
      const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
      let {data,status} = await get3WorkOrderPermission()
      //判断新工单权限
       if(!(data.flag && menuInfo)){
         await this.getNewWorkOrderPermission()
       }
      // 具体路由跳转逻辑
      switch (item.type) {
        case 'roomNumberAudit':
          // 房号审核
          this.$router.push({
            name: 'communityRoomAction!list.action1',
            query: {
              status: 0
            }
          })
          break
        case 'userInformationAudit':
          // 用户信息审核
          this.$router.push({
            name: 'userAction!examineList.action1',
            query: {
              fromPage: 'workbench'
            }
          })
          break
        case 'userRealNameAudit':
          // 用户实名审核
          this.$router.push({
            name: 'userRealNameAudit1',
            query: {
              fromPage: 'workbench'
            }
          })
          break
        case 'relatedOrganizationAudit':
          // 关联组织审核
          this.$router.push({
            name: 'userCollection1',
            query: {
              fromPage: 'workbench'
            }
          })
          break
        case 'organizationAudit':
          // 组织审核
          this.$router.push({
            name: 'orgVerify1',
            query: {
              approveStatus: 0
            }
          })
          break
        case 'organizeReport':
          // 组织举报
          this.$router.push({
            name: 'orgTipOff0',
            query: {
              status: 0
            }
          })
          break
        case 'volunteerAudit':
          // 志愿者审核
          this.$router.push({
            name: 'volunteer1',
            query: {
              status: 0
            }
          })
          break
        case 'newTicket':
          // 新工单3.0
          if (data.flag && menuInfo){
            this.$router.push({
              name:'iframePanel',
              query:{
                pid:1,
                cid:3400,
                fid:94751,
                sid:947241,
                start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                end:moment().add(1,'days').format('YYYY-MM-DD'),
                src:menuInfo.item.url,
                yshOrderStatus:1,
              }
            })
          }else {
            // 工单2.0
            debugger
            if(this.hasNewWorkOrderPermission) {
              this.$router.push({
                name: 'orderScheduling.ServiceWorkOrder',
                query: {
                  orderStatus: 1,
                  fromPage: 'workbench',
                  referStartTime:createStartTime,
                  referEndTime:createEndTime
                }
              })
            }else {// 老工单
              this.$router.push({
                name: 'orderAction!viewList.action?orderType=01',
                query: {
                  orderStatus: 1,
                  fromPage: 'workbench',
                  referEndTime,
                  referStartTime
                }
              })
            }
          }
          break
        case 'receiveTicketsOvertime':
          // 超时接收工单
          if(data.flag && menuInfo){
             this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:94751,
                  sid:947241,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  src:menuInfo.item.url,
                  yshOrderStatus:3,
                }
              })
          }else {
            if(this.hasNewWorkOrderPermission) {
              this.$router.push({
                name: 'orderScheduling.ServiceWorkOrder',
                query: {
                  orderStatus: 3,
                  fromPage: 'workbench',
                  referStartTime:createStartTime,
                  referEndTime:createEndTime
                }
              })
            }else {
              this.$router.push({
                name: 'orderAction!viewList.action?orderType=01',
                query: {
                  orderStatus: 1,
                  caseType: 2,
                  fromPage: 'workbench',
                  referEndTime,
                  referStartTime
                }
              })
            }
          }
          break
        case 'processingTicketOvertime':
          // 超时处理工单
          if(data.flag && menuInfo){
              this.$router.push({
                name:'iframePanel',
                query:{
                  pid:1,
                  cid:3400,
                  fid:94751,
                  sid:947241,
                  start:moment().subtract(90,'days').format('YYYY-MM-DD'),
                  end:moment().add(1,'days').format('YYYY-MM-DD'),
                  src:menuInfo.item.url,
                  yshOrderStatus:4,
                }
              })
          }else {
            if(this.hasNewWorkOrderPermission) {
              this.$router.push({
                name: 'orderScheduling.ServiceWorkOrder',
                query: {
                  orderStatus: 4,
                  fromPage: 'workbench',
                  referStartTime:createStartTime,
                  referEndTime:createEndTime
                }
              })
            }else {
              this.$router.push({
                name: 'orderAction!viewList.action?orderType=01',
                query: {
                  orderStatus: 6,
                  caseType: 6, // 和数据看板保持一致，取值 10天及以上未处理
                  fromPage: 'workbench',
                  referEndTime,
                  referStartTime
                }
              })
            }
          }
          break
        case 'processingOrderOvertime':
          // 超时处理订单
          //  提交时间段： 今日往前90天~今日
          referStartTime = moment().subtract(90, 'days').format('YYYY-MM-DD HH:mm')
          referEndTime = moment().format('YYYY-MM-DD HH:mm')
          this.$router.push({
            name: 'orderAction!viewList.action?orderType=11',
            query: {
              orderStatus: 6,
              caseType: 6, // 和数据看板保持一致，取值 10天及以上未处理
              fromPage: 'workbench',
              referEndTime,
              referStartTime
            }
          })
          break
        case 'userConsultation':
          // 用户咨询
          this.$router.push({
            name: 'serverOwnerConsultAction!list.action1',
            query: {
              fromPage: 'workbench'
            }
          })
          break
        case 'placeBookingOrder':
          // 场地预订订单
          this.$router.push({
            name: 'placeOrder',
            query: {
              status: 4
            }
          })
          break
        case 'cancelPlaceBooking':
          // 场地取消订单
          this.$router.push({
            name: 'placeOrder',
            query: {
              aftersaleStatus: 1
            }
          })
          break
        case 'carAudit':
          // 停车授权审核
          this.$router.push({
            name: 'parkingCardManagerByAudit'
          })
        case 'zfTodoList':
         window.open(item.menuUrl)
        default:
          break
      }
    },
    async queryTodoCount () {
      let { data } = await this.$axios.post(queryTodoCountURL, {
        modelIds: this.modelIds
      })
      let orderCount = 0
      this.todoList.forEach(item => {
        let target = data.find(d => item.refId === d.modelId)
        if (target) {
          item.count = target.count
        }
        if (this.orderMap[item.type]) {
          orderCount += item.count
        }
      })
      // if (orderCount > 0 && !counter) {
      if (orderCount > 0) {
        let audio = this.$refs.audioPlay
        audio.play()
      }
    },
    intervalTodo () {
      // let counter = 0
      if (!this.timer) {
        this.timer = setInterval(() => {
          // if (counter < 12) {
          //   counter++
          // } else {
          //   counter = 0
          // }
          this.queryTodoCount()
        }, 1000 * 60 * 5)
      }
    },
    async getNewWorkOrderPermission() {
      const { data, status } = await this.$axios.get(getNewWorkOrderPermission)
      if (status === 100) {
        if (data) {
          this.hasNewWorkOrderPermission = data
        }
      }
    }
  },
  mounted () {
    this.todoList = this.appModule.childreds && this.appModule.childreds.map(item => ({ type: item.classification, title: item.modelAlias, refId: item.refId, count: undefined,menuUrl:item.menuUrl }))
    this.modelIds = this.appModule.childreds && this.appModule.childreds.map(item => item.refId)
    this.queryTodoCount()
    this.intervalTodo()
  },
  watch: {
    appModule (newVal) {
      this.todoList = this.appModule.childreds && this.appModule.childreds.map(item => ({
        type: item.classification,
        title: item.modelAlias,
        refId: item.refId,
        count: undefined
      })
      )
      this.modelIds = this.appModule.childreds && this.appModule.childreds.map(item => item.refId)
      this.queryTodoCount()
      this.intervalTodo()
    }
  },
  destroyed () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.todo-panel-container {
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  .todo-item {
    width: 100%;
    line-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    background:#F2F6FA;
    padding: 0 24px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    .title {
      font-size: 16px;
    }
    .number {
      font-size: 18px;
    }
    &:hover {
      background: #5A8DEE;
      color: #fff;
      box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.37);
    }
  }
  .processingOrderOvertime {
    background: #FFF7F8;
    color: #FF4E77;
    &:hover {
      background: #FFF7F8;
      color: #FF4E77;
    }
  }
}
.checkbox-wrapper {
  padding: 0 14px;
  &:hover {
    box-shadow: 0px 2px 16px 0px rgba(27, 140, 242, 0.15);
    border-radius: 2px;
  }
}
.editor-dialog {
  cursor: pointer;
}
</style>
<style lang="scss">
.worktop-container .module .right {
  .todo-panel-container {
    padding-right: 14px;
  }
}
</style>
