var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("module-box", { attrs: { appModule: _vm.appModule } }, [
        _c(
          "div",
          { staticClass: "todo-panel-container" },
          _vm._l(_vm.todoList, function (item, index) {
            return _c(
              "div",
              {
                key: `${index}`,
                staticClass: "todo-item",
                class: item.type,
                on: {
                  click: function ($event) {
                    return _vm.linkTo(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(item.count || 0)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("audio", { ref: "audioPlay", attrs: { hidden: "true" } }, [
        _c("source", {
          attrs: {
            src: require("./media/youhavemessage.wav"),
            type: "audio/mpeg",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }